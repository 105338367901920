import React, { FC } from 'react';
import { Calendar, DateLocalizer, NavigateAction, View } from 'react-big-calendar';
import CustomEvent, { MyEvent } from '../CustomEvent/CustomEvent';
import eventPropGetter from '../../components/eventPropGetter';

interface DesktopCalendarProps {
    filteredEvents: MyEvent[];
    localizer: DateLocalizer;
    view: View;
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    onEventClick: (event: MyEvent) => void;
}

const DesktopCalendar: FC<DesktopCalendarProps> = ({
    localizer,
    filteredEvents,
    view,
    currentDate,
    setCurrentDate,
    onEventClick,
}) => {
    return (
        <div style={{ height: '100vh' }}>
            <Calendar<MyEvent>
                localizer={localizer}
                events={filteredEvents} // Используем отфильтрованные события
                startAccessor="start"
                endAccessor="end"
                style={{ height: '90%' }}
                components={{
                    toolbar: () => null,
                    event: CustomEvent,
                    /* month: {
                        event: CustomEventContainer,
                    } */
                }}
                view={view}
                date={currentDate}
                onNavigate={(date) => setCurrentDate(new Date(date))}
                eventPropGetter={eventPropGetter}
                onSelectEvent={(event) => onEventClick(event)}
            />
        </div>
    );
};

export default DesktopCalendar;
