import { create } from "zustand";
import { useWindowWidth } from "../../../hooks/useWindowWidth";

export type CalendarPage = 'Trainings' | 'Anmeldungen';
interface CalendarState {
    calendarPage: CalendarPage;
    setCalendarPage: (calendarPage: CalendarPage) => void;
    currentDate: Date;
    setCurrentDate: (currentDate: Date) => void
    /* Mobile */
    isMobileVersion: boolean;
    setIsMobileVersion: (width: number) => void;
    isCalendarMobile: boolean,
    setIsCalendarMobile: (isCalendarMobile: boolean) => void
}

export const useCalendarStore = create<CalendarState>((set) => ({
    calendarPage: 'Trainings',
    setCalendarPage: (calendarPage: CalendarPage) => set({ calendarPage }),
    currentDate: new Date,
    setCurrentDate: (currentDate: Date) => set({ currentDate }),
    isMobileVersion: false,
    setIsMobileVersion: (width: number) => {
        if (width >= 790) {
            set({ isMobileVersion: false })
        } else {
            set({ isMobileVersion: true })
        }
    },
    isCalendarMobile: false,
    setIsCalendarMobile: (isCalendarMobile: boolean) => set({isCalendarMobile})
    

}));
