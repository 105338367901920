import React, { MouseEvent, useEffect, useState } from 'react';
import styles from './GroupsList.module.css';
import useGroupStore from '../../store/groups';
import { truncateString } from '../../../../helpers/truncateString';
import { IGroup } from '../../../../models/group';

const GroupsList = () => {
    const groups = useGroupStore(store => store.groups);
    const changeVisible = useGroupStore(store => store.changeVisible);
    const deleteGroup = useGroupStore(store => store.deleteGroup);
    const fetchGroups = useGroupStore(store => store.fetchGroups);
    const changeOrder = useGroupStore(store => store.changeOrder);
    const setColor = useGroupStore(store => store.setColor);
    const setGroupName = useGroupStore(store => store.setGroupName);
    const setGroupUrl = useGroupStore(store => store.setGroupUrl);
    const setEditMode = useGroupStore(store => store.setEditMode);
    const setEditid = useGroupStore(store => store.setEditid);


    const [draggedGroupId, setDraggedGroupId] = useState<number | null>(null);
    const [draggedOverGroupId, setDraggedOverGroupId] = useState<number | null>(null);
    const handleEdit = (event: MouseEvent<HTMLSpanElement>,id: number) => {
        setEditid(id)
        event.stopPropagation();
        setEditMode(true)
        const group = groups.find(group => group.id == id ) as IGroup
        console.log(group)
        setGroupName(group.groupName)
        setGroupUrl(group.groupUrl)
        setColor(group.color)



    }
    const handleDelete = (event: MouseEvent<HTMLSpanElement>, id: number) => {
        event.stopPropagation();
        const userConfirmed = window.confirm('Sind Sie sicher, dass Sie diese Gruppe löschen möchten?');


        if(!userConfirmed)return

        deleteGroup(id);
    };

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        setDraggedGroupId(id);
        event.dataTransfer.effectAllowed = 'move';
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, targetId: number) => {
        event.preventDefault();
        if (draggedGroupId === null || draggedGroupId === targetId) return;
        setDraggedOverGroupId(targetId);
    };
  
    
    const handleDrop = (event: React.DragEvent<HTMLDivElement>, targetId: number) => {
        event.preventDefault();
        if (draggedGroupId === null || draggedGroupId === targetId) return;

        // Вывод в консоль id перетаскиваемого элемента и целевого элемента
        console.log("Перетаскиваемый элемент ID:", draggedGroupId);
        console.log("Целевой элемент ID:", targetId);
        changeOrder(draggedGroupId,targetId)
       
        // Сброс draggedGroupId после завершения действия
        setDraggedGroupId(null);
        setDraggedOverGroupId(null);
    };

    useEffect(()=>{
        fetchGroups()
    },[])
    return (
        <div className={styles.main}>
            <div className={styles.titles}>
                <span className={styles.desktopTite}>Gruppenname</span>
                <span className={`${styles.link}`}>Gruppenlink</span>
                <span className={styles.colorTitle}>Farbe</span>
                <div className={styles.titlesRight}>
                    <span className={styles.order}>Sortierung</span>
                    <span>Sichtbar</span>
                    <span className={styles.deleteTitle}>Löschen</span>
                </div>
            </div>
            <div className={styles.list}>
                {groups.map(group => (
                    <div
                        className={`${styles.item} ${draggedOverGroupId === group.id ? styles.dragOver : ''}`}
                        key={group.id}
                        draggable
                        onDragStart={(event) => handleDragStart(event, group.id)}
                        onDragEnter={(event) => handleDragEnter(event, group.id)}
                        onDrop={(event) => handleDrop(event, group.id)}
                        onDragOver={(event) => event.preventDefault()} // предотвращает поведение по умолчанию
                        onClick = {(event) => handleEdit(event, group.id)}
                    >
                 

                      
                        <span>{group.groupName}</span>
                        <span className={styles.link}>{truncateString(group.groupUrl,100) }</span>
                        <span>
                            <div className={styles.color} style={{ background: `${group.color}` }}></div>
                        </span>
                  
                        <div className={styles.itemRight}>
                            <span className={styles.order}>{group.order}</span>
                            <input
                                type="checkbox"
                                checked={group.visible}
                                onChange={() => changeVisible(group.id)}
                                onClick={event => event.stopPropagation()}
                            />
                            <span className={styles.delete} onClick={(event) => handleDelete(event, group.id)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 7H20H4ZM16 7V4C16 3.73478 15.8946 3.48043 15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3H9C8.73478 3 8.48043 3.10536 8.29289 3.29289C8.10536 3.48043 8 3.73478 8 4V7H16ZM18 20V7H6V20C6 20.2652 6.10536 20.5196 6.29289 20.7071C6.48043 20.8946 6.73478 21 7 21H17C17.2652 21 17.5196 20.8946 17.7071 20.7071C17.8946 20.5196 18 20.2652 18 20Z"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupsList;
