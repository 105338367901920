import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { IGroup } from "../../../models/group";
import { ILocation } from "../../../models/location";
import { ITraining } from "../../../models/ITraining";


export default class TrainingManagerService {
    static async getGroup(): Promise<AxiosResponse<IGroup[]>> {
        return $api.get('/groups')
    }
    static async getLocations(): Promise<AxiosResponse<ILocation[]>> {
        return $api.get('/locations')
    }

    static async postTraining(startTime: string, endTime: string, group: string, location: string, repeat: number): Promise<AxiosResponse<ITraining>> {
        return $api.post('/trainings', {
            startTime,
            endTime,
            repeat_type: repeat,
            groupId: group,
            locationId: location,
        })
    }
    static async getTrainingById(id: string): Promise<AxiosResponse<ITraining>> {
        return $api.get(`/trainings/${id}`)
    }

    static async editTraining(id: number, startTime: string, endTime: string): Promise<AxiosResponse<ITraining>> {
        return $api.put(`/trainings/${id}`, {
            startTime: startTime,
            endTime: endTime,
        })
    }
    static async editTrainingAll(id: number, startTime: string, endTime: string): Promise<AxiosResponse<ITraining>> {
        return $api.put('/trainings', {
            trainingDatesId: id,
            startTime: startTime,
            endTime: endTime,
        })
    }
    static async deleteTraining(id: string, reason: string): Promise<AxiosResponse> {
        return $api.delete(`/trainings/${id}`, {
            data: {
                reason
            }
        })
    }

    static async deleteAllTrainings(trainingDatesId: string, reason: string): Promise<AxiosResponse<ITraining>> {
        return $api.delete('/trainings', {
            data: { trainingDatesId, reason }
        })
    }
}