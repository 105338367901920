import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Event as BigCalendarEvent } from 'react-big-calendar';
import styles from './CustomEvent.module.css'
export interface MyEvent extends BigCalendarEvent {
  color?: string; // Добавляем поле color
  date: string;
  id: number
}

const CustomEvent: React.FC<EventProps<MyEvent>> = ({ event }) => {
    return (
      <div className = {styles.event}style={{ backgroundColor: event.color || '#3174ad', padding: '5px', borderRadius: '4px', color: 'white' }}>
        <div className={styles.title}>{event.title}   </div> 
        <div className={styles.date}>{event.date}</div>
      </div>
    );
  };
  
  export default CustomEvent;
  