import React, { MouseEvent, useEffect, useState } from 'react';
import styles from './LocationsList.module.css';
import { truncateString } from '../../../../helpers/truncateString';
import { ILocation } from '../../../../models/location';
import useLocationStore from '../../store/locations';

const LocationsList = () => {
    const locations = useLocationStore(store => store.locations);
    const changeVisible = useLocationStore(store => store.changeVisible);
    const deleteLocation = useLocationStore(store => store.deleteLocation);
    const fetchLocations = useLocationStore(store => store.fetchLocations);
    const changeOrder = useLocationStore(store => store.changeOrder);
    const setLocationName = useLocationStore(store => store.setLocationName);
    const setLocationUrl = useLocationStore(store => store.setLocationUrl);
    const setEditMode = useLocationStore(store => store.setEditMode);
    const setEditId = useLocationStore(store => store.setEditId);

    const [draggedLocationId, setDraggedLocationId] = useState<number | null>(null);
    const [draggedOverLocationId, setDraggedOverLocationId] = useState<number | null>(null);

    const handleEdit = (event: MouseEvent<HTMLSpanElement>, id: number) => {
        setEditId(id);
        event.stopPropagation();
        setEditMode(true);
        const location = locations.find(location => location.id === id) as ILocation;
        setLocationName(location.locationName);
        setLocationUrl(location.locationUrl);
    };

    const handleDelete = (event: MouseEvent<HTMLSpanElement>, id: number) => {
        event.stopPropagation();
        const userConfirmed = window.confirm('Sind Sie sicher, dass Sie diesen Ort löschen möchten?');
        if (!userConfirmed) return;
       
        deleteLocation(id);
    };

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, id: number) => {
        setDraggedLocationId(id);
        event.dataTransfer.effectAllowed = 'move';
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>, targetId: number) => {
        event.preventDefault();
        if (draggedLocationId === null || draggedLocationId === targetId) return;
        setDraggedOverLocationId(targetId);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, targetId: number) => {
        event.preventDefault();
        if (draggedLocationId === null || draggedLocationId === targetId) return;
        changeOrder(draggedLocationId, targetId);
        setDraggedLocationId(null);
        setDraggedOverLocationId(null);
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.titles}>
                <span>Ortname</span>
                <span className={styles.link}>Ortlink</span>
                <div className={styles.titlesRight}>
                    <span className={styles.order}>Sortierung</span>
                    <span>Sichtbar</span>
                    <span>Löschen</span>
                </div>
            </div>
            <div className={styles.list}>
                {locations.map(location => (
                    <div
                        className={`${styles.item} ${draggedOverLocationId === location.id ? styles.dragOver : ''}`}
                        key={location.id}
                        draggable
                        onDragStart={(event) => handleDragStart(event, location.id)}
                        onDragEnter={(event) => handleDragEnter(event, location.id)}
                        onDrop={(event) => handleDrop(event, location.id)}
                        onDragOver={(event) => event.preventDefault()}
                        onClick={(event) => handleEdit(event, location.id)}
                    >
                        <span>{location.locationName}</span>
                        <span className={styles.link}>{truncateString(location.locationUrl, 100)}</span>
                        <div className={styles.itemRight}>
                            <span className={styles.order}>{location.order}</span>
                            <input
                                type="checkbox"
                                checked={location.visible}
                                onChange={() => changeVisible(location.id)}
                                onClick={event => event.stopPropagation()}
                            />
                            <span className={styles.delete} onClick={(event) => handleDelete(event, location.id)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 7H20H4ZM16 7V4C16 3.73478 15.8946 3.48043 15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3H9C8.73478 3 8.48043 3.10536 8.29289 3.29289C8.10536 3.48043 8 3.73478 8 4V7H16ZM18 20V7H6V20C6 20.2652 6.10536 20.5196 6.29289 20.7071C6.48043 20.8946 6.73478 21 7 21H17C17.2652 21 17.5196 20.8946 17.7071 20.7071C17.8946 20.5196 18 20.2652 18 20Z"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LocationsList;
