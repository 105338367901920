// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationManager_main__lEzVA{
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    width: 100%;
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/LocationManager/LocationManager.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".main{\n    display: flex;\n    flex-direction: column;\n    padding: 15px 20px;\n    width: 100%;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `LocationManager_main__lEzVA`
};
export default ___CSS_LOADER_EXPORT___;
