import React, { FC, useEffect, useState } from "react";
import moment from "moment-timezone";
import MySelect, { SelectOption } from "../../UI/MySelect/MySelect";
import { useNavigate, useParams } from "react-router-dom";
import { formatGroups } from "./helpers/formatGroup";
import TrainingManagerService from "./api/TrainingManagerService";
import { formatLocations } from "./helpers/formatLocations";
import HeaderButton from "../../UI/HeaderButton/HeaderButton";
import CreateTrainingIcon from "../../assets/images/commit-training.svg";
import EditTrainingIcon from "../../assets/images/edit-training.svg";
import DeleteBin from "../../assets/images/delete-bin.svg";
import MyInput from "../../UI/MyInput/MyInput";
import styles from "./TrainingManager.module.css";
import PopUpMenu from "../../UI/PopUpMenu/PopUpMenu";
import PageLayout from "../../layouts/PageLayout/PageLayout";

interface TrainingManagerProps {
  action: "create" | "edit";
}

const TrainingManager: FC<TrainingManagerProps> = ({ action }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    repeat: "",
    startTime: "",
    endTime: "",
    location: "",
    group: "",
  });

  const [repeatOptions] = useState<SelectOption[]>([
    { value: "1", label: "Jeden Tag" },
    { value: "2", label: "Jede Woche" },
    { value: "3", label: "Jeden Monat" },
    { value: "4", label: "Einmalig" },
  ]);

  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([]);


  const popUpDeleteOptions = [
    { value: "1", label: "Einmal löschen" },
    { value: "2", label: "Alle löschen" }
  ]
  const popUpUpdateOptions = [
    { value: "1", label: "Einmal aktualisieren" },
    { value: "2", label: "Alle aktualisieren" }
  ]

  const [answer, setAnswer] = useState({ value: '', reason: '' })
  const [editType, setEditType] = useState<string>('');
  const [deleteType, setDelteType] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);
  const [showDeleteMenu, setShowDeleteMenu] = useState<boolean>(false);

  const handleInputChange = (name: string, value: string) => {
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchGroups = async () => {
    try {
      const { data } = await TrainingManagerService.getGroup();
      setGroupOptions(formatGroups(data));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchLocations = async () => {
    try {
      const { data } = await TrainingManagerService.getLocations();
      setLocationOptions(formatLocations(data));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTrainingById = async () => {
    try {
      const { data } = await TrainingManagerService.getTrainingById(id!);


      const berlinStartDate = moment
        .tz(data.startTime, "Europe/Berlin")
        .format("YYYY-MM-DDTHH:mm");
      const berlinEndDate = moment
        .tz(data.endTime, "Europe/Berlin")
        .format("YYYY-MM-DDTHH:mm");

      setFormData({
        repeat: String(data.repeatType),
        startTime: berlinStartDate,
        endTime: berlinEndDate,
        location: String(data.location.id),
        group: String(data.group.id),
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchGroups();
    fetchLocations();
    fetchTrainingById();
  }, []);
  const [errors, setErrors] = useState({
    repeat: false,
    startTime: false,
    endTime: false,
    location: false,
    group: false,
  });

  const validateFields = () => {
    const newErrors = {
      repeat: !formData.repeat,
      startTime: !formData.startTime,
      endTime: !formData.endTime,
      location: !formData.location,
      group: !formData.group,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  const createTraining = async () => {
    if (validateFields()) {
      const berlinStartDate = moment.tz(formData.startTime, "Europe/Berlin").format();
      const berlinEndDate = moment.tz(formData.endTime, "Europe/Berlin").format();
      try {
        await TrainingManagerService.postTraining(
          berlinStartDate,
          berlinEndDate,
          formData.group,
          formData.location,
          parseInt(formData.repeat)
        );
        navigate("/trainigs");
      } catch (error) {
        console.error(error);
      }
    }
  };
  const [actionTypeError,setActionTypeArror] = useState<boolean>(false);

  const editTraining = async () => {
    if(editType.trim() == ''){
      setActionTypeArror(true)
      return;
    }
    if (validateFields()) {
      const berlinStartDate = moment.tz(formData.startTime, "Europe/Berlin").format();
      const berlinEndDate = moment.tz(formData.endTime, "Europe/Berlin").format();
      try {
        if (editType === '2') {
          await TrainingManagerService.editTrainingAll(parseInt(id!), berlinStartDate, berlinEndDate);
        } else if (editType === '1') {
          await TrainingManagerService.editTraining(parseInt(id!), berlinStartDate, berlinEndDate);
        }
        navigate("/trainigs");
      } catch (error) {
        console.error(error);
      }
    }
  };
   
  const deleteTraining = async () => {
    if(deleteType.trim() == ''){
      setActionTypeArror(true)
      return;
    }
    
    try {
      if (deleteType == "1") {
        await TrainingManagerService.deleteTraining(id!, reason);
      } else if (deleteType == "2") {
        await TrainingManagerService.deleteAllTrainings(id!, reason);
      }
      
      setShowDeleteMenu(false)
      navigate("/trainigs");
    } catch (e) {
      console.error(e);
    }

  };

  return (
    <PageLayout
      title="Training bearbeiten"
      headereComopnent={
        <div className={styles.buttonRow}>
          {action === "edit" ? (
            <>
              <HeaderButton
                className="delete"
                icon={DeleteBin}
                onClick={() => setShowDeleteMenu(true)}
              />
              <HeaderButton
                icon={EditTrainingIcon}
                onClick={() => setShowEditMenu(true)}
              />
            </>
          ) : (
            <HeaderButton icon={CreateTrainingIcon} onClick={createTraining} />
          )}
        </div>
      }
    >
      <div className={styles.addTrainingPage}>
        <div className={styles.group}>
          <div className={styles.title}>Wiederholungstyp</div>
          <MySelect
            options={repeatOptions}
            onChange={(value) => handleInputChange("repeat", value)}
            placeholder="Wiederholungstyp"
            value={formData.repeat}
            disabled={action === "edit"}
            className={styles.select}
            hasError={errors.repeat}
          />
        </div>
        <div className={styles.group}>
          <div className={styles.group}>
            <div className={styles.title}>Trainingsbeginn</div>
            <MyInput
              type="datetime-local"
              value={formData.startTime}
              setValue={(value) => handleInputChange("startTime", value)}
              className={styles.input}
              hasError={errors.startTime}
            />
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Trainingsende</div>
            <MyInput
              type="datetime-local"
              value={formData.endTime}
              setValue={(value) => handleInputChange("endTime", value)}
              className={styles.input}
              hasError={errors.endTime}
            />
          </div></div>
        <div className={styles.group}>
          <div className={styles.group}>
            <div className={styles.title}>Ort</div>
            <MySelect
              options={locationOptions}
              onChange={(value) => handleInputChange("location", value)}
              placeholder="Tennisanlage"
              value={formData.location}
              disabled={action === "edit"}
              className={styles.select}
              hasError={errors.location}
            />
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Gruppe</div>
            <MySelect
              options={groupOptions}
              onChange={(value) => handleInputChange("group", value)}
              placeholder="Gruppe"
              value={formData.group}
              disabled={action === "edit"}
              className={styles.select}
              hasError={errors.group}
            />
          </div>   </div>
      </div>
      <PopUpMenu
        title="Training löschen"
        submitButtonTitle="Löschen"
        closeButtonTitle="Zurück"
        onSubmit={deleteTraining}
        show={showDeleteMenu}
        setShow={setShowDeleteMenu}

      >

        <MySelect
          options={popUpDeleteOptions}
          onChange={setDelteType}
          placeholder="Typ auswählen"
          className={styles.actionTypeSelect}
          hasError ={actionTypeError}
        />
       
        <textarea
          placeholder="Löschgrund"
          value={reason}
          onChange={event => setReason(event.target.value)}
          className={`${styles.reason}`}
        />
      
      </PopUpMenu>

      <PopUpMenu
        title="Training bearbeiten"
        submitButtonTitle="Speichern"
        closeButtonTitle="Zurück"
        onSubmit={editTraining}
        show={showEditMenu}
        setShow={setShowEditMenu}
        
      >

        <MySelect
          options={popUpUpdateOptions}
          onChange={setEditType}
          placeholder="Typ auswählen"
          className={styles.actionTypeSelect}
          hasError ={actionTypeError}
        />


      </PopUpMenu>
    </PageLayout>
  );
};

export default TrainingManager;
