import React, { FC, useMemo } from 'react';
import moment from 'moment';
import styles from './MobileCalendar.module.css';
import { View } from 'react-big-calendar';
import { MyEvent } from '../CustomEvent/CustomEvent';

/* interface Event {
    id: number;
    title: string;
    start: Date;
    end: Date;
    color?: string;
}
 */
interface DayEvents {
    date: Date;
    events: MyEvent[];
}

// Интерфейс для пропсов компонента MobileCalendar
interface MobileCalendarProps {
    events: MyEvent[];
    view: View;
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    onEventClick: (event: MyEvent) => void;
}

const MobileCalendar: FC<MobileCalendarProps> = ({
    events,
    view,
    currentDate,
    setCurrentDate,
    onEventClick,
}) => {
    // Определяем начало и конец текущего месяца, чтобы фильтровать события
    const startOfMonth = moment(currentDate).startOf('month').toDate();
    const endOfMonth = moment(currentDate).endOf('month').toDate();

    // Фильтруем события для текущего месяца
    const eventsByDay = useMemo((): DayEvents[] => {
        const eventsByDay: { [key: string]: MyEvent[] } = {};
        events.forEach(event => {
            const dateKey = moment(event.start).format('YYYY-MM-DD');
            if (!eventsByDay[dateKey]) {
                eventsByDay[dateKey] = [];
            }
            eventsByDay[dateKey].push(event);
        });

        return Object.entries(eventsByDay)
            .filter(([date]) =>
                moment(date).isBetween(startOfMonth, endOfMonth, undefined, '[]')
            )
            .map(([date, events]) => ({
                date: new Date(date),
                events: events,
            }));
    }, [events, startOfMonth, endOfMonth]);

    const goToPreviousMonth = () => {
        setCurrentDate(moment(currentDate).subtract(1, 'month').toDate());
    };

    const goToNextMonth = () => {
        setCurrentDate(moment(currentDate).add(1, 'month').toDate());
    };

    return (
        <div className={styles.container}>

            {/* Event List */}
            <div className={styles.eventList}>
                {eventsByDay.map(dayEvents => (
                    <div key={dayEvents.date.toString()} className={styles.dayEventsContainer}>
                        <div className={styles.dayLabel}>
                            {moment(dayEvents.date).format('ddd DD')}
                        </div>
                        {dayEvents.events.map((event, index) => (
                            <div
                                key={index}
                                className={styles.eventCard}
                                style={{
                                    backgroundColor: event.color || '#333',
                                }}
                                onClick={() => onEventClick(event)}
                            >
                                <div className={styles.eventTitle}>{event.title}</div>
                                <div className={styles.eventTime}>
                                    {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
                                </div>
                                
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MobileCalendar;
