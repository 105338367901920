import React, { useEffect, useState } from 'react';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import MyCalendarNav from '../../components/MyCalendarNav/MyCalendarNav';
import HeaderButton from '../../UI/HeaderButton/HeaderButton';
import { RouteNames } from '../../app/router';
import { useNavigate } from 'react-router-dom';
import { MyEvent } from '../../components/MyCalendar/components/CustomEvent/CustomEvent';
import CalendarService from './api/CalendarService';
import { formatTrainigsToEvents } from './helpers/formatTrainigsToEvents';
import addTrainingIcon from '../../assets/images/add-training.svg';
import { View, Views } from 'react-big-calendar';
import MySelect, { SelectOption } from '../../UI/MySelect/MySelect';
import styles from './Calendar.module.css';
import { formatApplicationsToEvents } from './helpers/formatAplicationsToEvents';
import MobileCalendar from '../../components/MyCalendar/components/MobileCalendar/MobileCalendar';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import MyCalendar from '../../components/MyCalendar/MyCalendar';
import { CalendarPage, useCalendarStore } from './store/calendarPage';



const Calendar = () => {
    const [view, setView] = useState<View>(Views.MONTH); // Управление видом (месяц или неделя)
    const currentDate = useCalendarStore(store => store.currentDate);
    const setCurrentDate = useCalendarStore(store => store.setCurrentDate);
    const [events, setEvents] = useState<MyEvent[]>([]);
    const setCalendarPage = useCalendarStore(store => store.setCalendarPage);
    const calendarPage = useCalendarStore(store => store.calendarPage);
    const isMobileVersion = useCalendarStore(store => store.isMobileVersion)
    const isCalendarMobile = useCalendarStore(store => store.isCalendarMobile)
    const setIsCalendarMobile = useCalendarStore(store => store.setIsCalendarMobile)

    const navigate = useNavigate();


    useEffect(()=>{
        return ()=>{
            setIsCalendarMobile(false)
        }
    },[])
    // Опции для выбора страницы и вида
    const calendarOptions: SelectOption[] = [
        { value: '1', label: 'Trainings' },
        { value: '2', label: 'Anmeldungen' }
    ];
    const viewOptions: SelectOption[] = [
        { value: Views.MONTH, label: 'Monat' },
        { value: Views.WEEK, label: 'Woche' }
    ];

    const handleAddTraining = () => {
        navigate(RouteNames.ADD_TRAININGS);
    };

    const onEventClick = (event: MyEvent) => {
        if (calendarPage === 'Anmeldungen') {
            navigate(`/application/${event.id}`);
        } else if (calendarPage === 'Trainings') {
            navigate(`/edit-training/${event.id}`);
        }
    };

    const fetchTrainigs = async () => {
        try {
            const { data } = await CalendarService.getTrainigs(currentDate);
            const newEvents = formatTrainigsToEvents(data);
            setEvents(newEvents);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchApplications = async () => {
        try {
            const { data } = await CalendarService.getAplucations(currentDate);
            const newEvents = formatApplicationsToEvents(data);
            setEvents(newEvents);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (calendarPage === 'Trainings') {
            fetchTrainigs();
        } else {
            fetchApplications();
        }
    }, [calendarPage, currentDate]);

    const handleCalendarTypeSelect = (value: string) => {
        const option = calendarOptions.find(option => option.value === value);
        if (option) setCalendarPage(option.label as CalendarPage);
    };

    const handleViewSelect = (value: string) => {
        setView(value as View);
    };

    const handleNavigate = (date: Date) => {
        const newDate = new Date(date.setHours(0, 0, 0, 0));
        setCurrentDate(newDate);
    };

    // Логика для отслеживания свайпа
    let touchStartX = 0;
    let touchEndX = 0;

    const handleTouchStart = (e: React.TouchEvent) => {
        touchStartX = e.changedTouches[0].screenX;
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        touchEndX = e.changedTouches[0].screenX;
        handleSwipe();
    };

    const handleSwipe = () => {
        if (touchEndX < touchStartX) {
            // Свайп влево — увеличить месяц
            const newDate = new Date(currentDate);
            newDate.setMonth(newDate.getMonth() + 1);
            setCurrentDate(newDate);
        }
        if (touchEndX > touchStartX) {
            // Свайп вправо — уменьшить месяц
            const newDate = new Date(currentDate);
            newDate.setMonth(newDate.getMonth() - 1);
            setCurrentDate(newDate);
        }
    };
    const width = useWindowWidth()
    const handleMobileCalendarVersion  = ()=> {
        width <= 700 && setIsCalendarMobile(!isCalendarMobile)
    }
    return (
        <PageLayout
            title={!isMobileVersion ? 'Kalender' : ''}
            headereComopnent={
                <>
                    <MyCalendarNav isMobileVersion={isMobileVersion} view={view} currentDate={currentDate} setCurrentDate={handleNavigate} />
                    <div className={styles.headerButtonRow}>
                        <MySelect
                            options={calendarOptions}
                            onChange={handleCalendarTypeSelect}
                            value='1'
                            className={styles.selectPage}
                        />
                        {!isMobileVersion &&
                            <MySelect
                                options={viewOptions}
                                onChange={handleViewSelect}
                                value={view}
                                className={`${styles.selectPage} ${styles.selectView}`}
                            />}
                        {calendarPage === 'Trainings' && !isMobileVersion && (
                            <HeaderButton icon={addTrainingIcon} onClick={handleAddTraining} />
                        )}
                    </div>
                </>
            }
        >
            <div
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={handleMobileCalendarVersion}
            >
                <MyCalendar
                    isCalendarMobile={isCalendarMobile}
                    view={view}
                    currentDate={currentDate}
                    onEventClick={onEventClick}
                    setCurrentDate={handleNavigate}
                    events={events}
                />
                {isMobileVersion && 
                <HeaderButton icon={addTrainingIcon} onClick={handleAddTraining} className={styles.AddMobileIcon}/>}
            </div>
        </PageLayout>
    );
};

export default Calendar;
