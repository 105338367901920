import React, { useEffect, useState } from 'react'
import styles from './NavBar.module.css';
import logo from '../../assets/images/logo.png'
import navCalendarIcon from '../../assets/images/nav-calendar.svg'
import navListIcon from '../../assets/images/nav-list.svg';
import { Link, useLocation } from 'react-router-dom';
import { RouteNames } from '../../app/router';
import NavDropDownBtn, { DropDownListItem } from '../../UI/NavDropDownBtn/NavDropDownBtn';
import { link } from 'fs';
import useAppStore from '../../store/app';
const NavBar = () => {
    const isNavBarShow = useAppStore(store => store.isNavBarShow)
    const location = useLocation();
    const dropDownList:DropDownListItem[]= [
        {
            title: 'groups',
            link: RouteNames.GROUPS_MANAGER
        },
        {
            title: 'locations',
            link: RouteNames.LOCATIONS_MANAGER
        }
    ]
    return (
        <div className={`${styles.navBar} ${isNavBarShow ? styles.open : ''}`}>
            <img src={logo} alt="" className={styles.logo} />
            <Link to={RouteNames.CALENDAR} className={`${styles.navButton} ${location.pathname == RouteNames.CALENDAR ? styles.active:''}`}>
                <img src={navCalendarIcon} alt="" className={styles.navIcon} />
            </Link>
            <NavDropDownBtn dropDownList = {dropDownList}/>
        </div>
    )
}

export default NavBar
