// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MobileCalendar.module.css */


.MobileCalendar_container__0HSft{
    padding: 20px 15px;
}
.MobileCalendar_header__3udzZ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.MobileCalendar_button__4LR9x {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.MobileCalendar_monthLabel__CtuqW {
    font-size: 20px;
    font-weight: bold;
}

.MobileCalendar_eventList__JslfW {
    overflow-y: auto;
    max-height: 70vh;
}

.MobileCalendar_dayEventsContainer__8tpWS {
    margin-bottom: 20px;
}

.MobileCalendar_dayLabel__PzbLD {
    font-size: 18px;
    font-weight: bold;
    color: #aaa;
}

.MobileCalendar_eventCard__05RF8 {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    margin: 5px 0;
}

.MobileCalendar_eventTitle__P1\\+XN {
    font-weight: bold;
}

.MobileCalendar_eventTime__Op\\+lf {
    font-size: 14px;
}

/* Optional: style for event location (commented out in the component)
.eventLocation {
    font-size: 12px;
    color: #ccc;
}
*/
`, "",{"version":3,"sources":["webpack://./src/components/MyCalendar/components/MobileCalendar/MobileCalendar.module.css"],"names":[],"mappings":"AAAA,8BAA8B;;;AAG9B;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;;;;;CAKC","sourcesContent":["/* MobileCalendar.module.css */\n\n\n.container{\n    padding: 20px 15px;\n}\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 20px;\n}\n\n.button {\n    background: none;\n    border: none;\n    color: #fff;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.monthLabel {\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.eventList {\n    overflow-y: auto;\n    max-height: 70vh;\n}\n\n.dayEventsContainer {\n    margin-bottom: 20px;\n}\n\n.dayLabel {\n    font-size: 18px;\n    font-weight: bold;\n    color: #aaa;\n}\n\n.eventCard {\n    background-color: #333;\n    color: #fff;\n    padding: 10px;\n    border-radius: 8px;\n    margin: 5px 0;\n}\n\n.eventTitle {\n    font-weight: bold;\n}\n\n.eventTime {\n    font-size: 14px;\n}\n\n/* Optional: style for event location (commented out in the component)\n.eventLocation {\n    font-size: 12px;\n    color: #ccc;\n}\n*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MobileCalendar_container__0HSft`,
	"header": `MobileCalendar_header__3udzZ`,
	"button": `MobileCalendar_button__4LR9x`,
	"monthLabel": `MobileCalendar_monthLabel__CtuqW`,
	"eventList": `MobileCalendar_eventList__JslfW`,
	"dayEventsContainer": `MobileCalendar_dayEventsContainer__8tpWS`,
	"dayLabel": `MobileCalendar_dayLabel__PzbLD`,
	"eventCard": `MobileCalendar_eventCard__05RF8`,
	"eventTitle": `MobileCalendar_eventTitle__P1+XN`,
	"eventTime": `MobileCalendar_eventTime__Op+lf`
};
export default ___CSS_LOADER_EXPORT___;
