import { useEffect, useState } from 'react'
import MyInput from '../../UI/MyInput/MyInput';
import styles from './LoginPage.module.css';
import MyButton from '../../UI/MyButton/MyButton';
import { useAuthStore } from '../../app/store/auth';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../app/router';
import loginLogo from '../../assets/images/login-logo.png'

const LoginPage = () => {
  const [username,setUsername] = useState<string>('');
  const [password,setPassword] = useState<string>('');
  const login = useAuthStore(store=> store.login);
  const role = useAuthStore(store=> store.role);
  
  const navigate = useNavigate();
  useEffect(()=>{
    console.log(role)
      if(role == 'admin'){
        navigate(RouteNames.CALENDAR);
      }
  },[role])

  const hendelLogin = async () => {
    try{
      await login(username,password);
    }catch(e){
      console.log(e)
    }

  }
  return (
    <div className={styles.page}>
      <div className={styles.main}>
        <img src={loginLogo} alt="login Logo" />
          <div className={styles.inputRow}>
            <div className={styles.title}>Username</div>
            <MyInput type='text'  value={username} setValue={setUsername} placeholder='Username eingeben'/>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.title}>Passwort</div>
            <MyInput type='password'  value={password} setValue={setPassword} placeholder='Passwort eingeben'/>
          </div>
        <MyButton onClick={hendelLogin}>Anmelden</MyButton>
      </div>
    </div>
  )
}

export default LoginPage
