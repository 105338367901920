import React, { FC } from 'react';
import moment from 'moment';
import styles from './MyCalendarNavMobile.module.css';
import MySelect, { SelectOption } from '../../../../UI/MySelect/MySelect';
import { createSelectOptions } from '../../helpers/createSelectOptions';

interface MyCalendarNavMobileProps {
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
}

// Создаем массив месяцев
const months = moment.months();

const MyCalendarNavMobile: FC<MyCalendarNavMobileProps> = ({ currentDate, setCurrentDate }) => {
    const currentYear = moment().year();
    const selectedYear = moment(currentDate).year();

    // Обновляем метки для месяцев, добавляя год, если это не текущий год
    const monthOptions: SelectOption[] = months.map((month, index) => ({
        value: index.toString(),
        label: selectedYear !== currentYear ? `${month} ${selectedYear}` : month,
    }));

    // Обработчик для изменения месяца
    const handleMonthChange = (value: string) => {
        const newMonth = parseInt(value, 10);
        const updatedDate = moment(currentDate).set("month", newMonth).toDate();
        setCurrentDate(updatedDate);
    };

    return (
        <>
            <MySelect
                options={monthOptions}
                onChange={handleMonthChange}
                className={styles.select}
                placeholder="Select Month"
                value={moment(currentDate).month().toString()}
            />
        </>
    );
};

export default MyCalendarNavMobile;
