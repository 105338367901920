import path from 'path';
import React from 'react';
import LoginPage from '../../pages/LoginPage/LoginPage';
import AddTraining from '../../pages/AddTraining/AddTraining';
import Application from '../../pages/Application/Application';
import EditTraining from '../../pages/EditTraining/EditTraining';
import Calendar from '../../pages/Calendar/Calendar';
import LocationManager from '../../pages/LocationManager/LocationManager';
import GroupManager from '../../pages/GroupManager/GroupManager';



export interface IRoute{
    path:string;
    element: React.ComponentType;

}
export enum RouteNames{
    
    LOGIN ="/login",
    CALENDAR = "/",
    ADD_TRAININGS = "/add-trainings",
    APPLICATION = "/application/:id",
    EDIT_TRAINING = "/edit-training/:id",
    LOCATIONS_MANAGER = "/locations-manager",
    GROUPS_MANAGER = "/groups-manager",

}

export const adminRoutes:IRoute[] = [
  {path: RouteNames.ADD_TRAININGS, element: AddTraining},
  {path: RouteNames.APPLICATION, element: Application},
  {path: RouteNames.EDIT_TRAINING, element: EditTraining},
  {path: RouteNames.CALENDAR, element: Calendar},
  {path: RouteNames.LOCATIONS_MANAGER, element: LocationManager},
  {path: RouteNames.GROUPS_MANAGER, element: GroupManager}
]

export const publicRoutes:IRoute[] =[
  {path: RouteNames.LOGIN, element: LoginPage},
  {path: RouteNames.ADD_TRAININGS, element: AddTraining},
]