import React, { FC, ReactNode, useEffect, useState } from 'react';
import styles from './PopUpMenu.module.css';
import MySelect, { SelectOption } from '../MySelect/MySelect';

interface PopUpMenuProps {
    children: ReactNode
    title: string
    show: boolean
    onSubmit?: () => void;
    submitButtonTitle?: string;
    closeButtonTitle: string;
    setShow: (show: boolean) => void
}

const PopUpMenu: FC<PopUpMenuProps> = ({ children, title, show, onSubmit, submitButtonTitle, closeButtonTitle,setShow }) => {




    return (
        <div className={`${styles.popUpContainer}  ${show ? styles.show : ''}`}>
            <div className={`${styles.popUp}`}>
                <div className={styles.title}>{title}</div>
                <div className={styles.body}>
                    {children}
            
                </div>
                {submitButtonTitle !== '' &&<button className={styles.button} onClick={onSubmit}>{submitButtonTitle}</button>}
                <button className={`${styles.button} ${styles.close}`} onClick={() => setShow(false)}>{closeButtonTitle}</button>
            </div>
        </div>
    );
};

export default PopUpMenu;