import moment from "moment";
import { MyEvent } from "../../../components/MyCalendar/components/CustomEvent/CustomEvent";
import { formatTimeRange } from "../../../helpers/formatTimeRange";
import { IApliacation } from "../../../models/IAplication";

export const formatApplicationsToEvents = (applications: IApliacation[]): MyEvent[] => {
    return applications
        .filter(application => application.startDate && application.endDate) // Исключаем записи с null значениями
        .map(application => {
            return {
                id: application.id,
                title: `${application.playerName}`,
                start: moment(application.startDate).tz("Europe/Berlin").toDate(), // Используем moment для создания объекта Date
                end: moment(application.endDate).tz("Europe/Berlin").toDate(),
                color: application.group.color,
                date: formatTimeRange(
                    String(application.startDate),
                    String(application.endDate)
                )
            }
        });
}
