// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.LoginPage_page__SchHS{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LoginPage_main__wr8IE{
    width: 510px;
    height: 520px;
    box-sizing: border-box;
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}

.LoginPage_inputRow__1436H{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.LoginPage_title__RBt-o{
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/LoginPage.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,gDAAgD;IAChD,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":["\n.page{\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.main{\n    width: 510px;\n    height: 520px;\n    box-sizing: border-box;\n    display: flex;    \n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 40px;\n    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);\n    border-radius: 30px;\n}\n\n.inputRow{\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n}\n.title{\n    color: rgba(0, 0, 0, 0.6);\n    font-family: 'Roboto', sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 23px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `LoginPage_page__SchHS`,
	"main": `LoginPage_main__wr8IE`,
	"inputRow": `LoginPage_inputRow__1436H`,
	"title": `LoginPage_title__RBt-o`
};
export default ___CSS_LOADER_EXPORT___;
