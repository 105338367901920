// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomEvent_event__3f-x9 {
    font-family: 'Poppins';
    font-size: 14px;
    height: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px;
}

.CustomEvent_title__SnFhH {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/MyCalendar/components/CustomEvent/CustomEvent.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".event {\n    font-family: 'Poppins';\n    font-size: 14px;\n    height: 40px;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    padding: 2px;\n}\n\n.title {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 13px;\n    line-height: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"event": `CustomEvent_event__3f-x9`,
	"title": `CustomEvent_title__SnFhH`
};
export default ___CSS_LOADER_EXPORT___;
