// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MyCalendarNavMobile.module.css */
.MyCalendarNavMobile_main__O6Zzw {
    display: flex;
    justify-content: center;
    align-items: center;
  /*   padding: 10px;
    background-color: #f0f0f0; */
    column-gap: 10px;
}



.MyCalendarNavMobile_select__vE508 {
  /*   padding: 5px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc; */
    width: 120px;
    min-width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MyCalendarNav/components/MyCalendarNavMobile/MyCalendarNavMobile.module.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;gCAC8B;IAC5B,gBAAgB;AACpB;;;;AAIA;EACE;;;6BAG2B;IACzB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["/* MyCalendarNavMobile.module.css */\n.main {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  /*   padding: 10px;\n    background-color: #f0f0f0; */\n    column-gap: 10px;\n}\n\n\n\n.select {\n  /*   padding: 5px;\n    font-size: 16px;\n    border-radius: 4px;\n    border: 1px solid #ccc; */\n    width: 120px;\n    min-width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `MyCalendarNavMobile_main__O6Zzw`,
	"select": `MyCalendarNavMobile_select__vE508`
};
export default ___CSS_LOADER_EXPORT___;
