// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyInput_inputContainer__8feF\\+ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}


.MyInput_input__jaQn5 {

    width: 310px;
    height: 40px;

    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
    padding: 8.5px 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

}

.MyInput_input__jaQn5::placeholder {
    color: rgba(0, 0, 0, 0.60);
}
.MyInput_input__jaQn5[type="datetime-local"]{
    color: gray;
}
.MyInput_disabled__uU35Q{
    background: rgba(239, 239, 239, 0.3);
    cursor: not-allowed;
}


.MyInput_errorBorder__7Bs5f {
    border-color: red; /* Красная рамка при ошибке */
}

.MyInput_errorText__NWdlp {

    font-size: 12px;
    margin-top: 0px;
/* Input/Label */
font-family: 'Roboto';
font-style: normal;
font-weight: 400;

line-height: 23px;
color: #FB2424;



}


@media(max-width: 800px){
    .MyInput_input__jaQn5 {
        height: 35px;
        font-size: 16px;
    }
    .MyInput_errorText__NWdlp {
        font-size: 14px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/UI/MyInput/MyInput.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;;AAE3B;;;AAGA;;IAEI,YAAY;IACZ,YAAY;;IAEZ,qCAAqC;IACrC,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;AAErB;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,WAAW;AACf;AACA;IACI,oCAAoC;IACpC,mBAAmB;AACvB;;;AAGA;IACI,iBAAiB,EAAE,6BAA6B;AACpD;;AAEA;;IAEI,eAAe;IACf,eAAe;AACnB,gBAAgB;AAChB,qBAAqB;AACrB,kBAAkB;AAClB,gBAAgB;;AAEhB,iBAAiB;AACjB,cAAc;;;;AAId;;;AAGA;IACI;QACI,YAAY;QACZ,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".inputContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    \n}\n\n\n.input {\n\n    width: 310px;\n    height: 40px;\n\n    border: 1px solid rgba(0, 0, 0, 0.38);\n    border-radius: 8px;\n    padding: 8.5px 14px;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 23px;\n\n}\n\n.input::placeholder {\n    color: rgba(0, 0, 0, 0.60);\n}\n.input[type=\"datetime-local\"]{\n    color: gray;\n}\n.disabled{\n    background: rgba(239, 239, 239, 0.3);\n    cursor: not-allowed;\n}\n\n\n.errorBorder {\n    border-color: red; /* Красная рамка при ошибке */\n}\n\n.errorText {\n\n    font-size: 12px;\n    margin-top: 0px;\n/* Input/Label */\nfont-family: 'Roboto';\nfont-style: normal;\nfont-weight: 400;\n\nline-height: 23px;\ncolor: #FB2424;\n\n\n\n}\n\n\n@media(max-width: 800px){\n    .input {\n        height: 35px;\n        font-size: 16px;\n    }\n    .errorText {\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `MyInput_inputContainer__8feF+`,
	"input": `MyInput_input__jaQn5`,
	"disabled": `MyInput_disabled__uU35Q`,
	"errorBorder": `MyInput_errorBorder__7Bs5f`,
	"errorText": `MyInput_errorText__NWdlp`
};
export default ___CSS_LOADER_EXPORT___;
