import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { ITraining } from "../../../models/ITraining";
import { IApliacation } from "../../../models/IAplication";


export default class CalendarService{
    static async getTrainigs(date:Date):Promise<AxiosResponse<ITraining[]>>{
        return await $api.get('trainings/date?date=' + date);
    }
    static async getAplucations(date:Date):Promise<AxiosResponse<IApliacation[]>>{
        return $api.get('applications?date='+date)
    }
}